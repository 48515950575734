import { graphql } from "gatsby"
import parse from "html-react-parser"
import React from "react"
import Layout from "../components/Layouts/layout"
import InteriorDesign from "../components/Projects/interiordesign"
import SEO from "../components/seo"

const PagerInteriorDesign = props => {
  // console.log(props.data.wpPage.seo)

  let {
    location,
    data: {
      wpPage: { title, seo },
    },
  } = props

  return (
    <Layout location={location} title={title}>
      <SEO bodyClasses="casestudies interior-design" seo={seo} />
      <div className="container-fluid content d-none" id="content">
        <div className="row">
          <div className="col-12">
            {!!title && <h1 className="heading">{parse(title)}</h1>}
          </div>
        </div>
      </div>
      <InteriorDesign title={title} />
    </Layout>
  )
}

export default PagerInteriorDesign

export const pageInteriorDesign = graphql`
  {
    wpPage(databaseId: { eq: 17 }) {
      id
      title
      seo {
        ...WpYoastSEO
      }
    }
  }
`
